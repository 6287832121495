<template>
  <section>
    <h1 class="title is-2">¡Hola!</h1>
    <p>Este es el panel de administradores, aca podran manejar las variables de la plataforma.</p>
  </section>
</template>

<script>
export default {};
</script>

<style>

</style>
