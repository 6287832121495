import { render, staticRenderFns } from "./ListadoProyectos.vue?vue&type=template&id=72367cdb&"
import script from "./ListadoProyectos.vue?vue&type=script&lang=js&"
export * from "./ListadoProyectos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports