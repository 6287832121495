import { render, staticRenderFns } from "./SubirRecibos.vue?vue&type=template&id=12f3f9c9&"
import script from "./SubirRecibos.vue?vue&type=script&lang=js&"
export * from "./SubirRecibos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports